import React, { useRef } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'

import Contact from "../components/Contact/Contact"
import ContactSection from "../components/Home/ContactSection"


function ContactPageTemplate(props) {
    console.log(props)
    let data = props.data;

    // console.log(data)


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://trustdev.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://trustdev.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}

            locale={props.pageContext.language}
            metaData={constructMetaData(data.wpPage, props.pageContext.currentPage, props.pageContext.language)}
        // services={props.pageContext.servicesCategories}
        >
            <div>
                <section style={{backgroundColor: "rgb(243, 248, 254)"}}>
                    <ContactSection
                        data={data.wpTemplate.globalInformation?.contactForm}
                        contactInfo={data.wpTemplate.globalInformation?.contactInfo}
                    />
                </section>
                <section>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12110.984309672143!2d22.9382694!3d40.6354815!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a8393efc836055%3A0xfa06bb02add81752!2zVHJ1c3QgzpHOvc6xz4DPhM-Fzr7Ouc6xzrrOrg!5e0!3m2!1sel!2sgr!4v1676906507431!5m2!1sel!2sgr" width="100%" height="640" style={{border: "0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </section>
            </div>
        </Layout>
    )
}

export default ContactPageTemplate

export const pageQuery = graphql`query GET_CONTACT_PAGE($id: String, $templateId: String) {
    wpPage(id: { eq: $id}){
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
                sourceUrl
            }
        }
        title
        content
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
                workingHours {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`
